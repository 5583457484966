import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["title", "postcode"];

  showCompanyForm() {
    document.getElementById("company-form").style.display = "block";
    document.getElementById("team-icon").classList.add("sml--icon");
    document.getElementById("solo-icon").classList.add("hide");
    document.getElementById("single-user-link").classList.add("hide");
    document.getElementById("team-account-link").classList.add("hide");
    document.getElementById("title").focus();
    document.getElementById("registration-subtitle").innerHTML =
      "Let's get your team set up...";
  }

  companyNameInput() {
    let submitBtn = this.element.getElementsByClassName("submit-title-btn")[0];
    if (this.companyTitlePresent()) {
      submitBtn.classList.remove("disabled");
    } else {
      submitBtn.classList.add("disabled");
    }
  }

  postcodeInput() {
    let submitBtn =
      this.element.getElementsByClassName("postcode-title-btn")[0];
    if (this.companyPostcodePresent()) {
      submitBtn.classList.remove("disabled");
    } else {
      submitBtn.classList.add("disabled");
    }
  }

  submitTitle(event) {
    event.preventDefault();
    document.getElementById("new-company-title").classList.add("hide");
    document.getElementById("new-company-postcode").classList.remove("hide");
    this.showSubmittedTitle(this.titleTarget.value);
    document.getElementById("postcode").focus();
  }

  showSubmittedTitle(title) {
    document.getElementById("set-company-title").innerHTML =
      "<p><small class='info-text'>Team Title</small></p><h3>" +
      title +
      "</h3>";
  }

  submitPostcode(event) {
    event.preventDefault();
    document.getElementById("new-company-postcode").classList.add("hide");
    document.getElementById("team-icon").classList.add("hide");
    this.showSubmittedPostcode(this.postcodeTarget.value);
    document
      .getElementById("submit-company-create-form")
      .classList.remove("hide");
    document.getElementById("success-icon").classList.remove("hide");
  }

  showSubmittedPostcode(postcode) {
    document.getElementById("set-company-postcode").innerHTML =
      "<p><small class='info-text'>Postcode</small></p><h3>" +
      postcode +
      "</h3>";
  }

  companyTitlePresent() {
    if (this.titleTarget.value.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  companyPostcodePresent() {
    if (this.postcodeTarget.value.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  editTitle() {}
}
