import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "detail"];

  connect() {
    console.log("hello from edit course");
  }

  showForm() {
    this.formTarget.classList.remove("hide");
    this.detailTarget.classList.add("hide");
  }

  hideForm() {
    this.formTarget.classList.add("hide");
    this.detailTarget.classList.remove("hide");
  }
}
