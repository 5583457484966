import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    //set the height of the container to show the front and back of the flashcard
    let flashcardElement = this.element.getElementsByClassName("flashcard")[0];
    let flashcardFront =
      flashcardElement.getElementsByClassName("flashcard-front")[0];
    let flashcardBack =
      flashcardElement.getElementsByClassName("flashcard-back")[0];
    let flashcardFrontHeight = flashcardFront.offsetHeight;
    let flashcardBackHeight = flashcardFront.offsetHeight;
    let height = Math.max(flashcardFrontHeight, flashcardBackHeight);
    flashcardElement.setAttribute("style", "height: " + height + "px");
  }

  flip(event) {
    event.preventDefault();
    this.element.classList.toggle("flip--show-back");
  }
}
