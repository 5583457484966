import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["wrapper"];

  close(event) {
    event.preventDefault();
    var wrapper = this.wrapperTarget;
    wrapper.parentNode.removeChild(wrapper);
  }
}
