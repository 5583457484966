import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log(this.element);
    if (this.data.get("file") === "true") {
      let audioElement = this.element.getElementsByTagName("audio")[0];
      this.loadAudioJs(audioElement);
    }
  }

  loadAudioJs(audioElement) {
    let that = this;
    audiojs.events.ready(function () {
      var player = audiojs.create(audioElement, {
        play: function () {
          that.highlightText();
        },
        pause: function () {
          that.unHighlightText();
        },
      });
    });
  }

  highlightText() {
    this.element.classList.add("highlight-text");
  }

  unHighlightText() {
    this.element.classList.remove("highlight-text");
  }
}
