import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["displayChars", "charactersLeft"];
  // Counts maximun characters
  // const total comes from the DOM, data-count-max-chars, maximum characters value.
  connect() {
    console.log("count character is Connected");
  }

  count(e) {
    const charWritten = this.charactersLeftTarget.value.length;
    const total = this.element.getAttribute("data-max-chars") - charWritten;
    if (total >= 0) {
      this.displayCharsTarget.innerHTML = `${total} characters left`;
    } else {
      const to_delete = Math.abs(total);
      this.displayCharsTarget.innerHTML = `Please remove ${to_delete} character(s)`;
    }
  }
}
