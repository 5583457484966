import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "answer",
    "question",
    "correctDiv",
    "failDiv",
    "nextBtn",
    "completeQuizBtn",
  ];

  initialize() {
    this.showCurrentQuestion();
    this.nextBtnTarget.classList.add("hidden");
  }

  showCurrentQuestion() {
    this.questionTargets.forEach((el, i) => {
      el.classList.toggle("question--current", this.index == i);
    });
    // get question's right answers
    this.loadCorrectAnswers();
  }

  loadCorrectAnswers() {
    // Everytime we change to a new question, this function
    // will keep track of the correct answers in controller's data-correct-answers

    let ul = document.querySelectorAll(".question--current")[0].children[3];
    let answers = [];
    for (var i = 0; i < ul.childElementCount; i++) {
      let answer = ul.children[i].children[0].getAttribute("data-correct");
      if (answer == "true") {
        answers.push(1);
      } else {
        answers.push(0);
      }
    }
    this.data.set("correctAnswers", answers);
  }

  saveUserInput() {
    //saves User input in controller's data-user-answers
    var userSelection = [];
    // Current question, stores unordered list of <li>answers</li>
    var ul = document.querySelectorAll(".question--current")[0].children[3];
    for (var i = 0; i < ul.childElementCount; i++) {
      // populates an array with user's input for the question
      let answer_checked = ul.children[i].children[0].checked;
      if (answer_checked) {
        userSelection.push(1);
      } else {
        userSelection.push(0);
      }
    }
    this.data.set("userAnswers", userSelection);
  }
  getResults(e) {
    // Stores in data-user-answers choices from the user
    e.preventDefault();
    this.saveUserInput();
    // if they match show either correct or fail info message
    if (this.data.get("correctAnswers") == this.data.get("userAnswers")) {
      this.resetInfoDivs();
      this.correctDivTargets[this.index].classList.remove("hidden");
      // Displays the next Button
      if (this.questionTargets.length - 1 != this.index) {
        this.nextBtnTarget.classList.remove("hidden");
      } else {
        this.completeQuizBtnTarget.classList.remove("hidden");
        this.nextBtnTarget.classList.add("hidden");
      }
    } else {
      this.resetInfoDivs();
      this.failDivTargets[this.index].classList.remove("hidden");
      this.nextBtnTarget.classList.add("hidden");
      this.completeQuizBtnTarget.classList.add("hidden");
    }
  }

  resetInfoDivs() {
    // Hides both fail and correct info messages
    this.failDivTargets[this.index].classList.add("hidden");
    this.correctDivTargets[this.index].classList.add("hidden");
  }

  // Question slides behaviour
  get index() {
    return parseInt(this.data.get("index"));
  }

  set index(value) {
    const old_val = this.index;
    this.data.set("index", value);
    if (this.index > this.questionTargets.length - 1) {
      this.data.set("index", old_val);
      this.nextBtnTarget.classList.add("hidden");
    }
    this.showCurrentQuestion();
    this.nextBtnTarget.classList.add("hidden");
  }

  get questionAnswers() {
    return this.data.get("correctAnswers");
  }

  nextQuestion(e) {
    e.preventDefault();
    this.index++;
    // Clears users input so we can start with a new question
    this.data.set("userAnswers", "");
    if (this.index + 1 == this.questionTargets.length) {
      this.nextBtnTarget.classList.add("hidden");
    } else {
    }
    // Stops the audio if it where playing
    document.querySelectorAll("audio")[this.index - 1].pause();
  }
}
