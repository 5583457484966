import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["email", "label"];

  validateEmail() {
    var email = this.emailTarget.value;
    if (this.validateEmailTest(email)) {
      this.emailTarget.classList.remove("error");
      this.labelTarget.classList.add("hide");
    } else {
      this.emailTarget.classList.add("error");
      this.labelTarget.classList.remove("hide");
    }
  }

  validateEmailTest(email) {
    if (email.length > 0) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    } else {
      return true;
    }
  }
}
