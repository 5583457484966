import { Controller } from "@hotwired/stimulus";
import axios from "axios-on-rails";

export default class extends Controller {
  static targets = ["wrapper"];

  connect() {
    var that = this;
    $(this.wrapperTarget).fullCalendar({
      header: {
        left: "title",
        center: "month,agendaWeek,agendaDay",
        right: "prev,next today",
      },
      eventTextColor: "#fff",
      events: this.data.get("path"),
      eventClick: function (event) {
        that.showEvent(event);
      },
    });
    this.watchForTabs();
  }

  watchForTabs() {
    var that = this;
    $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
      $(that.wrapperTarget).fullCalendar("render");
    });
  }

  showEvent(event) {
    console.log("hello");
    axios.get(this.data.get("path") + "/" + event.id).then(function (response) {
      swal({
        html: response.data,
        showCloseButton: false,
        showConfirmButton: false,
      });
    });
  }
}
