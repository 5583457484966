import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["message"];

  connect() {
    var isNewChrome =
      (navigator.userAgent.match(/Chrom(e|ium)\/(\d+)/) || [])[2] > 56;
    var targetDiv = this.messageTarget;

    if (!isNewChrome || this.isHandheldDevice()) {
      // If the User is not using Chrome or coming from mobile, get rid of the message
      targetDiv.textContent = "";
    }
  }
  isHandheldDevice() {
    var lDevicesUserAgents = [
      "blackberry",
      "android",
      "iphone",
      "ipad",
      "symbian",
      "smartphone",
      "ios",
      "windows ce",
      "webos",
    ];
    var lDeviceUserAgentString = navigator.userAgent.toLowerCase();
    for (var i = 0; i < lDevicesUserAgents.length; ++i) {
      if (lDeviceUserAgentString.indexOf(lDevicesUserAgents[i]) != -1)
        return true;
    }
    return false;
  }
}
