import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option", "correctResult", "failResult"];

  display_answer(is_correct) {
    this.failResultTarget.classList.add("hidden");
    this.correctResultTarget.classList.add("hidden");

    if (is_correct) {
      this.correctResultTarget.classList.toggle("hidden");
      this.failResultTarget.classList.add("hidden");
    } else {
      this.correctResultTarget.classList.add("hidden");
      this.failResultTarget.classList.toggle("hidden");
    }
  }

  checkResult(e) {
    var is_correct = false;
    var option_chosen;

    for (var i = 0; i < this.optionTargets.length; i++) {
      if (
        this.optionTargets[i].checked &&
        this.optionTargets[i].getAttribute("data-correct") === "true"
      ) {
        option_chosen = this.optionTargets[i];
        is_correct = true;
      }
    }
    this.display_answer(is_correct);
  }
}
