import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["player", "uploader", "errorDiv"];

  connect() {
    $(this.playerTarget).audioPlayer();
  }
  check() {
    // Check after clicking upload button if a file has the extension .mp3
    const fileUploaded = this.uploaderTarget.files[0];

    if (fileUploaded.type !== "audio/mp3") {
      this.errorDivTarget.classList.remove("hidden");
      this.errorDivTarget.innerText =
        "File format is not valid, please try again with a .mp3 file";
    } else {
      this.errorDivTarget.classList.add("hidden");
    }
  }
}
