import { Controller } from "@hotwired/stimulus";
import axios from "axios-on-rails";

export default class extends Controller {
  static targets = ["submitButton", "successMessage", "purchasingArea"];

  connect() {
    var key = this.data.get("key");
    this._stripeObject = Stripe(key);
    var elements = this._stripeObject.elements();
    var style = {
      base: {
        color: "#32325d",
      },
    };

    this._cardObject = elements.create("card", { style: style });

    this._cardObject.mount("#card-element");
    this._cardObject.on("change", ({ error }) => {
      const displayError = document.getElementById("card-errors");
      if (error) {
        displayError.textContent = error.message;
      } else {
        displayError.textContent = "";
      }
    });
  }

  submit(ev) {
    var that = this;
    ev.preventDefault();
    that.submitButtonTarget.disabled = true;
    that.stripeObject
      .confirmCardPayment(that.clientSecret, {
        payment_method: {
          card: this.cardObject,
          billing_details: {
            name: that.customerName,
          },
        },
        receipt_email: that.customerEmail,
      })
      .then(function (result) {
        if (result.error) {
          const displayError = document.getElementById("card-errors");
          displayError.textContent = result.error.message;
          that.submitButtonTarget.disabled = false;
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === "succeeded") {
            const displayError = document.getElementById("card-errors");
            that.purchasingAreaTarget.classList.add("hide");
            that.successMessageTarget.classList.remove("hide");
            axios.post(that.successPath);
          }
        }
      });
  }

  get stripeObject() {
    return this._stripeObject;
  }

  get cardObject() {
    return this._cardObject;
  }

  get customerEmail() {
    return this.data.get("customerEmail");
  }

  get successPath() {
    return this.data.get("successPath");
  }

  get customerName() {
    return this.data.get("customerName");
  }

  get clientSecret() {
    return this.data.get("clientSecret");
  }
}
