import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "emailTemplate",
    "emailInput",
    "multipleEmailsInput",
    "cancelOptions",
    "options",
    "multipleEmailsInputWrapper",
    "invitationForm",
    "areYouSure",
    "invitationWrapper",
  ];

  addAnotherEmail(event) {
    event.preventDefault();
    this.emailInputTarget.insertAdjacentHTML(
      "beforeend",
      this.emailTemplateTarget.innerHTML
    );
  }

  removeEmail(event) {
    event.preventDefault();
    let parentNode = event.target.parentElement.parentElement.parentElement;
    parentNode.removeChild(event.target.parentElement.parentElement);
  }

  addMultipleEmails(event) {
    event.preventDefault();
    this.multipleEmailsInputWrapperTarget.classList.remove("hide");
    this.emailInputTarget.classList.add("hide");
    this.cancelOptionsTarget.classList.remove("hide");
    this.optionsTarget.classList.add("hide");
    this.multipleEmailsInputTarget.value = this.getEnteredEmails();
  }

  cancelAddingMultipleEmails(event) {
    event.preventDefault();
    this.multipleEmailsInputTarget.value = "";
    this.multipleEmailsInputWrapperTarget.classList.add("hide");
    this.optionsTarget.classList.remove("hide");
    this.emailInputTarget.classList.remove("hide");
    this.cancelOptionsTarget.classList.add("hide");
  }

  getEnteredEmails() {
    let emailInputs = this.element.getElementsByClassName("email-input");
    let allEmails = "";
    _.forEach(emailInputs, function (input) {
      allEmails += input.value + " ";
    });
    return allEmails;
  }

  areYouSure() {
    this.areYouSureTarget.classList.remove("hide");
    this.invitationWrapperTarget.classList.add("hide");
  }

  notSure() {
    this.areYouSureTarget.classList.add("hide");
    this.invitationWrapperTarget.classList.remove("hide");
  }

  inviteUsers() {
    var submittedEmails = "";
    if (this.multipleEmailsInputTarget.value.trim() === "") {
      var submittedEmails = this.getEnteredEmails();
    } else {
      var submittedEmails = this.multipleEmailsInputTarget.value.trim();
    }
    if (submittedEmails === "") {
      this.areYouSure();
    } else {
      this.multipleEmailsInputTarget.value = submittedEmails;
      this.invitationFormTarget.submit();
    }
  }
}
