import { Controller } from "@hotwired/stimulus";

import axios from "axios-on-rails";

export default class extends Controller {
  static targets = ["term", "results"];

  connect() {
    console.log("seach controller");
  }
  search(event) {
    var searchTerm = this.termTarget.value;
    var searchPath = this.data.get("path");

    var that = this;

    axios
      .get(searchPath, {
        params: {
          search_term: searchTerm,
        },
      })
      .then(function (response) {
        that.resultsTarget.innerHTML = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
